.main-layout {
  height: 100vh;
}

.main-layout .ant-layout-sider-below {
  position: fixed;
  z-index: 999;
  min-height: 100%;
  height: 400px;
}

.content-layout {
  overflow-y: auto;
  height: 100vh;
}

.main-sidebar {
  position: fixed;
  height: 100vh;
}
