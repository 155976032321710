
:root {
  --bs-body-bg: #ffffff; 
  --bs-body-color: #212529;
  --bs-body-bg : #fff;
  --bs-border-color:#dee2e6;
  --bs-border-width:1px;
  --bs-border-radius: 0.375rem;
  --bs-card-spacer-y: 1rem;
  --bs-card-spacer-x: 1rem;
  --bs-card-title-spacer-y: 0.5rem;
  --bs-card-title-color: var(--bs-body-color); 
  --bs-card-subtitle-color: rgba(var(--bs-body-color-rgb), 0.7); 
  --bs-card-border-width: var(--bs-border-width, 1px); 
  --bs-card-border-color: var(--bs-border-color-translucent, rgba(0, 0, 0, 0.125)); 
  --bs-card-border-radius: var(--bs-border-radius, 0.25rem); // Default border radius
  --bs-card-box-shadow: none; // No shadow by default
  --bs-card-inner-border-radius: calc(var(--bs-card-border-radius) - var(--bs-card-border-width));
  --bs-card-cap-padding-y: 0.5rem;
  --bs-card-cap-padding-x: 1rem;
  --bs-card-cap-bg: rgba(var(--bs-body-color-rgb), 0.03); // Subtle cap background
  --bs-card-cap-color: inherit; // Inherit text color
  --bs-card-height: auto; // Auto height
  --bs-card-color: var(--bs-body-color); // Default text color
  --bs-card-bg: var(--bs-body-bg, #fff); // Default background color
  --bs-card-img-overlay-padding: 1rem;
  --bs-card-group-margin: 0.75rem; // Default group margin
}
.card-space{
  margin-top: 25px;
}
.pyament-button{
  text-align: right;
  display: flex;
  justify-content: end;
  gap: 8px;
  display: flex;
}
.buy-button{
  margin-right: 25px !important;
}
.pyament-button button{
  background-color: #5c7a93;
  color: white;
}
.secondary-heading {
  margin: 0;
  font-weight: 600;
}

.amount-text {
  font-weight: 600;
  text-align: right;
}
.fee-summary{
  display: flex;
  justify-content: space-around;
}
.payment-submit{
  width: 100%;
  display: flex;
  justify-content: end;
  margin-top: 20px;
}
.payment-submit .cancel{
  margin-right: 15px;
  background-color: #dc3545;
  color: white;
}
.submit-button{
  background-color: #5c7a93;
  color: white;
}
.request-button{
  text-align: right;
}
.cart-button{
  margin-right: 30px;
}
.local-bootstrap {
  // @import "~bootstrap/scss/functions";
  // @import "~bootstrap/scss/variables"; // Import variables so all CSS variables are defined
  // @import "~bootstrap/scss/mixins";
  // @import '~bootstrap/dist/css/bootstrap.min.css';
  @import "~bootstrap/scss/bootstrap"; // Import Bootstrap first
@import "~formiojs/dist/formio.full.min.css"; // Import Form.io CSS
.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  height: var(--bs-card-height);
  color: var(--bs-card-color);
  word-wrap: break-word;
  background-color: var(--bs-card-bg);
  background-clip: border-box;
  border: 1px solid #dee2e6 !important;
  border-radius: var(--bs-card-border-radius);
}
}

.bg-success{
  background-color: rgb(92, 122, 147) !important;
}
.formio-component-submit button {
  display: none !important; /* Hide the element */
}

.error {
  color: #dc3545 !important;
}

.proceed-button{
  background-color: rgb(92, 122, 147);
  color:white;
  margin-top: 25px;
}