.overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 200;
    cursor: pointer;
  }
  .spinner-over {
    position: absolute;
    left: 50%;
    top: 50%;
    height: 100px;
    width: 100px;
    margin: 0px auto;
 
 
 
    border-radius: 100%;
    z-index: 100;
  }
  .spinner-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 200;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black overlay */
  }
 
  .spinner-container {
    padding: 20px;
    background-color: rgba(255, 255, 255, 0.8); /* Semi-transparent white container */
    border-radius: 4px;
  }
 
  .ant-alert {
    margin-bottom: 0;
  }
  .ant-spin-lg {
    .ant-spin-dot{
      i{
      background-color: #2f5e6c !important;
    }
    }
  }

  .search{
    border-radius: 33px !important;
  }

  .ant-modal-close-icon svg{
fill:black;
  }

  .spinner-containers {
     position: relative;
 top: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.7); /* Optional overlay */
    z-index: 1000;
  }
  ul.list-group li.list-group-header .col-md-2 {
    display: none !important;
  }
  
  .fileSize {
    display: none !important;
  }

  ul.list-group li.list-group-item .col-md-2 {
    display: none;
  }
  .iframe {
    text-align: center !important;
  }
  .iframe iframe{
    text-align: center;
    height: 450px;
    border-image: none !important;
    border: none;
    width: 100%;
    text-align: center !important;
  }
  .first-frame iframe{
    height: 385px;
    border-image: none !important;
    border: none; width: 100%;
  }