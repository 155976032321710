.content-wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.break-word {
  word-break: break-word;
}

.section-wrapper {
  background-color: #ffffff;
  padding: 16px;
  border-radius: 8px;
  border: 1px solid #f2f2f2;
}

.comment-message-wrap {
  display: flex;
  padding: 16px;
  gap: 8px;
  flex-direction: column;
  background: #f3f3f3;
  border-radius: 8px;
}

.comment-header-wrap {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.comment-profile-box {
  display: flex;
  gap: 16px;
  align-items: center;
}

.comment-profile-icon {
  background-color: #606060;
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
  padding: 7px;
  border-radius: 100%;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.comment-text {
  font-size: 16px;
  font-weight: 400;
  color: #5b5b5b;
}

.comment-text p {
  margin: 0;
  padding-bottom: 8px;
  font-size: 16px;
  font-weight: 400;
  color: #5b5b5b;
}

.comment-profile-name {
  font-size: 16px;
  font-weight: 600;
  color: #202224;
  margin: 0;
}

.comment-date {
  color: #7a7a7a;
  font-size: 12px;
  font-weight: 400;
}

.comment-doc-attachment,
.docment-name-style,
.toggle-label {
  font-size: 14px;
  font-weight: 500;
  color: #202224;
  margin: 0;
  cursor: pointer;
  line-height: normal;
  cursor: pointer;
}

.comment-doc-attachment {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 200px;
}


.comment-scroll-wrapper {
  height: auto;
  position: relative;
  display: flex;
  flex-direction: column;
}

.comment-scroll-box {
  height: 100%;
  position: relative;
  overflow-y: auto;
  max-height: 50vh;
  min-height: 25vh;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.comment-section-container .rdw-editor-wrapper {
  display: flex;
  flex-direction: column-reverse;
}

.comment-section-container .rdw-editor-wrapper .rdw-editor-main {
  min-height: 6vh;
}

.comment-section-container .rdw-editor-wrapper .rdw-editor-main .DraftEditor-root .DraftEditor-editorContainer .public-DraftEditor-content .public-DraftStyleDefault-block span {
  display: inline;
  word-break: break-word;
}

.document-icon-wrap {
  display: block;
  position: relative;
  width: 100%;
  min-width: 24px;
  max-width: 24px;
  height: 24px;
}

.doc-icon {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  object-fit: cover;
  cursor: pointer;
}

.document-action-wrap {
  display: flex;
  align-items: center;
  gap: 8px;
}

/* CSS to set widths for comment and document sections */
.comment-info-doc-wrap {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  gap: 16px;
}

.comment-info-wrapper {
  width: 70%;
  /* 70% width for the comment section */
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
}

.comment-document-list {
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: calc(100vh - 304px);
}

.comment-component-wrap {
  flex-basis: 60%;
}

.document-component-wrap {
  flex-basis: 40%;
}

.full-submission-info-wrap {
  flex-basis: 100%;
}

.half-submission-info-wrap {
  flex-basis: 40%;
}

.half-file-viewer-wrap {
  flex-basis: 60%;
  background-color: #ffffff;
  padding: 16px;
  border-radius: 8px;
  overflow-y: auto;
  height: calc(100vh - 174px);
}

.fileViewer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 1rem;
}

.icon-wrap {
  border: none;
  background: transparent;
  box-shadow: none;
  height: unset;
  display: flex;
}

.view-request-heading {
  display: flex;
  align-items: center;
  gap: 8px;
}

.request-information-wrap {
  display: flex;
  gap: 8px;
  align-items: baseline;
}

.information-label {
  font-size: 16px;
  font-weight: 500;
}

.information-text {
  font-size: 14px;
  font-weight: 400;
}

.form-tab-btn {
  font-size: 16px;
  font-weight: 500;
  border: none;
  background-color: #f0f0f0;
  border-radius: 4px;
  color: #5a7890;
  line-height: 1em;
  padding: 4px 16px;
}

.tab-buttons-wrap .ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn .form-tab-btn {
  background-color: #5a7890;
  color: #ffffff;
}

.tab-buttons-wrap .ant-tabs-ink-bar {
  background-color: #5a7890;
}

.mb-1 {
  margin-bottom: 8px;
}

.mb-2 {
  margin-bottom: 16px;
}

.m-0 {
  margin: 0;
}

.p-0 {
  padding: 0;
}

.secondary-heading {
  font-size: 18px;
  font-weight: 600;
  color: #5a7890;
}

.document-row-wrapper {
  display: flex;
  gap: 8px;
}

.document-row-wrap {
  display: flex;
  align-items: center;
  gap: 4px;
}

.all-doc-column {
  display: flex;
  flex-direction: column;
  gap: 6px;
  height: 100%;
  overflow: auto;
}

.all-document-row-wrap {
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: space-between;
  border: 1px solid #ededed;
  padding: 4px 8px;
  border-radius: 8px;

}

.architectural-header-wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  gap: 16px;
  margin-bottom: 16px;
}

.architectural-header-left-wrap {
  display: flex;
  gap: 24px;
  align-items: center;
  width: fit-content;
}

.architectural-header-left-wrap .ant-form-item {
  margin-bottom: 0;
}

.architectural-header-right-wrap {
  display: block;
  position: relative;
  width: fit-content;
}

.table-scroll .ant-table {
  overflow: auto;
  height: calc(100vh - 290px);
}

.primary-button,
.secondary-button {
  border-radius: 17px !important;
}

.pyament-button {
  text-align: right;
  display: flex;
  justify-content: end;
  gap: 8px;
  display: flex;
}

.comment-content-grid {
  position: relative;
  border: 1px solid #ebebeb;
  border-radius: 8px;
  padding: 8px;
  max-height: 40vh;
  overflow: auto;
}

.comment-content-grid .rdw-editor-toolbar {
  border: 0;
  width: fit-content;
  padding: 0;
  margin: 0;
  gap: 8px;
}

.comment-content-grid .rdw-editor-toolbar .rdw-inline-wrapper {
  margin-bottom: 0;
  gap: 8px;
}

.comment-content-grid .rdw-editor-toolbar .rdw-inline-wrapper .rdw-option-wrapper,
.comment-content-grid .rdw-editor-toolbar .rdw-list-wrapper .rdw-option-wrapper {
  margin: 0;
  border: 0;
}

.comment-content-grid .rdw-editor-toolbar .rdw-list-wrapper {
  margin-bottom: 0;
  gap: 8px;
}

.comment-pannel-bottom-row {
  display: flex;
  gap: 16px;
  align-items: flex-end;
  justify-content: flex-end;
  position: relative;
  width: 100%;
}

.comment-additional-icon-wrap {
  display: flex;
  align-items: flex-end;
  gap: 8px;
}

.comment-additional-icon-wrap .additional-icon {
  padding: 0;
  height: unset;
  line-height: unset;
  display: flex;
}

.comment-pannel-bottom-row .ant-form-item {
  margin: 0;
}

.comment-pannel-label {
  position: absolute;
  font-size: 14px;
  font-weight: 400;
  color: #5b5b5b;
  top: 22px;
  left: 10px;
  z-index: 0;
  pointer-events: none;
}

.Add-comment-button {
  font-size: 14px;
  font-weight: 400;
  background-color: #5a7890;
  border: 1px solid #5a7890;
  color: #ffffff;
  height: unset;
  line-height: 1em;
  display: flex;
  align-items: center;
  padding: 8px 16px;
  font-weight: 500;
}

.Add-comment-button:hover {
  background-color: #46647e !important;
  border: 1px solid #46647e !important;
  color: #ffffff !important;
}

.comment-pannel-bottom-row .ant-upload-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

.comment-additional-icon-wrap .ant-upload-wrapper .ant-upload-list {
  display: flex;
  align-items: center;
}

.comment-additional-icon-wrap .ant-upload-wrapper .ant-upload-list .ant-upload-list-item-container .ant-upload-list-item {
  height: unset;
  border: 0;
  border-left: 1px solid #cdcdcd;
  border-radius: 0;
  padding: 0 10px;
  margin-left: 8px;
}

.comment-additional-icon-wrap .ant-upload-wrapper .ant-upload-list .ant-upload-list-item-container .ant-upload-list-item .ant-upload-list-item-thumbnail {
  height: 24px;
  width: 24px;
  line-height: 24px;
}

.comment-additional-icon-wrap .ant-upload-wrapper .ant-upload-list .ant-upload-list-item-container .ant-upload-list-item .ant-upload-list-item-thumbnail .anticon-picture {
  font-size: 24px;
}

.comment-pannel-buttons-wrap {
  display: flex;
  gap: 8px;
}

.req-back-button {
  background: #5a7890 !important;
  border-color: transparent !important;
  color: white !important;
  border-radius: 6px;
}

.comment-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 8px;
}

.comment-header-inner-wrap {
  display: flex;
  gap: 16px;
}

.comment-header-inner-wrap .ant-form-item {
  margin-bottom: 0;
}

.comment-search-bar-wrapper .ant-input-outlined {
  border-color: #a1a1a1;
}

.comment-filter-wrap {
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 4px;
}

.uploaded-file-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #f9f9f9;
  width: 32%;
}

.uploaded-file-item span {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 90%;
}

.uploaded-files {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  height: 100%;
  max-height: 15vh;
  overflow: auto;
}

.comment-doc-wrapper {
  display: flex;
  width: 100%;
  gap: 8px;
}

.ant-layout-content:has(.submission-page-main-wrapper) {
  background: transparent !important;
}

.main-inner-wrapper:has(.submission-page-main-wrapper) {
  padding: 0 !important;
}

.main-inner-wrapper:has(.arch-request-page) {
  padding: 8px 24px 0 !important;
}

.new-arch-request-header {
  display: flex;
  gap: 16px;
  align-items: center;
}

/* FORM IO DESIGN START */
/* General styles for all labels */
.custom-form-io-design .formio-component {
  margin-bottom: 16px;
}

.custom-form-io-design .formio-component .formio-component-htmlelement p {
  margin-bottom: 0;
}

.custom-form-io-design .formio-component .formio-component-htmlelement p span {
  color: #5b5b5b !important;
}

.custom-form-io-design label {
  font-size: 14px !important;
  color: #444444;
  display: block;
  font-weight: 500;
  padding-bottom: 4px !important;
}

.custom-form-io-design label.form-check-label {
  font-weight: 400;
}

/* General input styles */
.custom-form-io-design input[type="text"],
.custom-form-io-design input[type="email"],
.custom-form-io-design input[type="password"],
.custom-form-io-design input[type="number"],
.custom-form-io-design textarea {
  width: 100% !important;
  padding: 8px !important;
  margin-bottom: 10px !important;
  border: 1px solid #cdcdcd !important;
  border-radius: 4px !important;
  font-size: 14px !important;
}

/* Focus styles for inputs 
.custom-form-io-design input[type="text"]:focus,
.custom-form-io-design input[type="email"]:focus,
.custom-form-io-design input[type="password"]:focus,
.custom-form-io-design input[type="number"]:focus,
.custom-form-io-design textarea:focus {
  border-color: #2B7754;
  outline: none;
  box-shadow: 0 0 5px rgba(43, 119, 84, 0.5);
}
*/

/* Radio and checkbox styles */
.custom-form-io-design input[type="radio"],
.custom-form-io-design input[type="checkbox"] {
  margin-right: 10px;
  cursor: pointer;
  border-color: #cdcdcd;
}

/* .custom-form-io-design input[type="radio"]:focus,
.custom-form-io-design input[type="checkbox"]:focus {
  outline: 2px solid #2B7754;
  outline-offset: 2px;
} */

/* Div with ref="input" styling */
.custom-form-io-design div.formio-editor-read-only-content[ref*="input"] {
  padding: 10px;
  background-color: #F6F8F9;
  border: 1px solid #cdcdcd;
  border-radius: 4px;
  min-height: 45px;
}

.custom-form-io-design .formio-component-file ul li.list-group-header {
  padding: 10px;
  background-color: #F6F8F9;
  border: 1px solid #cdcdcd;
  border-radius: 4px 4px 0 0;
  min-height: 45px;
}

.custom-form-io-design .formio-component-file .fileSelector {
  border: 1px dashed #cdcdcd;
  border-top: 0;
}

.custom-form-io-design .formio-component-file ul li.list-group-header strong {
  color: #5b5b5b;
  font-weight: 600;
}

.local-bootstrap .card.card-body {
  border: none !important;
  padding: 0;
  margin-bottom: 0 !important;
}

/* Styling textarea specifically */
.custom-form-io-design textarea {
  min-height: 100px !important;
  resize: vertical;
}

/* Custom styles for disabled inputs */
.custom-form-io-design input:disabled,
.custom-form-io-design textarea:disabled {
  background-color: #F6F8F9;
  border: 1px solid #cdcdcd;
  cursor: not-allowed;
  opacity: 1 !important;
  margin-right: 0;
  margin-top: 4px;
}

/* Submit button styles 
.custom-form-io-design input[type="submit"] {
  background-color: #2B7754;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
}

.custom-form-io-design input[type="submit"]:hover {
  background-color: #236042;
}
*/

/* FORM IO DESIGN END */

/* TENENT FORM DESIGN START */
.form-add-field-header {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1rem 0 2rem;
}

.custom-fields-form .ant-form-item-label {
  padding-bottom: 0;
}

.custom-fields-form .ant-form-item-label label {
  font-weight: 500;
}

.custom-input-box-wrap .ant-form-item-row {
  flex-wrap: wrap;
}

.custom-input-box-wrap .ant-form-item-row .ant-form-item-label {
  width: 100%;
  display: flex;
}

.custom-input-box-wrap .ant-form-item-row .ant-form-item-control {
  flex: auto;
}

.custom-input-box-wrap .ant-form-item {
  margin-bottom: 8px;
}

.ant-modal-title {
  font-size: 18px !important;
}

.custom-input-box-wrap .ant-form-item-control-input .ant-form-item-control-input-content .ant-radio-wrapper span {
  font-weight: 500;
}

/* TENENT FORM DESIGN END */

/* CUSTOM UPLOADER DESIGN*/
.other-file-uploader {
  width: 100%;
}

.custom-additional-upload-header {
  padding: 10px;
  background-color: #f6f6ff;
  border: 1px solid #cdcdcd;
  border-radius: 4px 4px 0 0;
  min-height: 45px;
}

.additional-upload-header-name {
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 0;
  color: #5b5b5b;
}

.additional-upload-header-name.extra-width {
  width: 60%;
  padding-left: 9%;
  margin-bottom: 0;
}

.additional-upload-header-name.small-width {
  width: 20%;
  text-align: center;
  margin-bottom: 0;
}

.additional-upload-content-wrap {
  display: flex;
  justify-content: center;
  gap: 8px;
  align-items: center;
}

.additional-upload-content-wrap .ant-upload-drag-icon {
  margin-bottom: 0 !important;
  margin-top: 0;
}

.additional-upload-content-wrap .ant-upload-drag-icon .anticon-cloud-upload {
  color: #000000 !important;
  font-size: 24px !important;
}

.ant-upload-wrapper .ant-upload-drag:has(.additional-upload-content-wrap) {
  background-color: transparent;
  border: 1px dashed #cdcdcd;
  border-top: 0;
  position: relative;
  padding: 15px;
  text-align: center;
  padding: 0;
  border-radius: 0;
}

.additional-upload-content-wrap .ant-upload-text {
  font-size: 14px !important;
  font-weight: 400 !important;
  color: #202020 !important;
}

.additional-upload-content-wrap .ant-upload-text span {
  color: #0d6efd;
}

.upload-field-delete-btn {
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
}

.custom-upload-table-wrap .ant-form-item {
  margin-bottom: 0;
}
.custom-upload-table-wrap .ant-table{
  overflow: auto;
}

.d-none {
  display: none;
}

.additional-upload-heading{
  font-size: 14px !important;
  color: #444444;
  display: block;
  font-weight: 500;
  padding-bottom: 4px !important;
  margin-bottom: 0 !important;
}

/* MEDIA QUERRY */
@media screen and (max-width: 1600px) {
  .comment-doc-attachment {
    max-width: 130px;
  }
}

@media screen and (max-width: 1366px) {
  .submission-page-main-wrapper {
    padding: 0 8px;
  }

  .comment-doc-wrapper {
    flex-wrap: wrap;
  }

  .comment-component-wrap,
  .document-component-wrap {
    flex-basis: 100%;
  }

  .comment-scroll-wrapper {
    max-height: unset;
    height: auto;
  }

  .comment-scroll-box {
    max-height: 40vh;
  }
}

@media screen and (max-width: 991px) {
  .comment-section-container .rdw-editor-wrapper {
    max-width: 100%;
    width: 100%;
  }
}

@media screen and (max-width: 901px) {
  .uploaded-file-item {
    width: 48%;
  }
}

@media screen and (max-width: 768px) {
  .architectural-header-left-wrap {
    flex-wrap: wrap;
    gap: 4px;
  }

  .architectural-header-wrapper {
    align-items: flex-end;
  }

  .search-bar-wrapper {
    width: 100%;
  }

  .section-heading {
    font-size: 22px;
  }

  .uploaded-file-item {
    width: 98%;
  }

  .comment-doc-attachment {
    max-width: 100px;
  }

  .uploaded-files {
    width: 100%;
    max-width: calc(100vw - 68px);
  }
}

@media screen and (max-width: 600px) {
  .comment-pannel-bottom-row {
    position: relative;
    right: unset;
    bottom: unset;
    width: 100%;
  }

  .comment-pannel-label {
    bottom: unset;
  }

  .comment-header-inner-wrap {
    gap: 8px;
    width: 100%;
  }

  .comment-header-inner-wrap .ant-form-item {
    width: 100%;
  }

  .comment-header {
    flex-wrap: wrap;
    align-items: flex-end;
    gap: 8px;
  }

  .section-wrapper {
    padding: 8px;
  }

  .comment-header-wrap {
    flex-direction: column-reverse;
    gap: 4px;
  }

  .comment-date {
    margin: 0;
  }

  .comment-profile-box {
    gap: 4px;
  }

  .comment-profile-icon {
    font-size: 12px;
    width: 24px;
    height: 24px;
  }

  .comment-profile-name {
    font-size: 14px;
  }

  .architectural-header-wrapper {
    flex-wrap: wrap;
    justify-content: flex-end;
  }

  .comment-doc-attachment {
    max-width: 80px;
  }

  .docment-name-style {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 200px;
  }
}

@media screen and (max-width: 420px) {
  .comment-doc-attachment {
    max-width: 50px;
  }

  .all-document-row-wrap {
    flex-direction: column;
    align-items: flex-start;
  }

  .document-action-wrap {
    width: 100%;
    justify-content: flex-end;
  }
}