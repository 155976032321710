@import './spinner';

.draggable-container {
  transition-duration: 200ms;
}

.draggable-over-div {
  border-radius: 8px;
  box-shadow: #4A6981 0px 0px 10px 3px;
}

.draggable-over-tr {
  border-radius: 8px;
  box-shadow: #4A6981 0px 0px 10px 3px inset;
}

.draggable-over-tr .ant-table-column-sort {
  background: none !important;
}

.custom-upload-item {
  display: contents;

  .ant-btn-default {
    background: initial;
  }
}

.react-grid-layout .ant-card-body {
  height: 100%;
}

.reportIframe>iframe {
  border: 0;
  align-self: center;
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 80vh;
  min-height: 900px;
  min-width: 670px;
}

#BV_FAQ_Report,
#BV_GeneralLedger,
#BV_ResidentMoveIn,
#BV_ResidentDirectory,
#BV_BodAndCommitteeDirectory,
#BV_CommunityVendorList,
#BV_CurrentBalancesWithAgingByResident,
#BV_ArchitecturalStatusCSVExport,
#BV_VMSTrusteeSalesProgress,
#BV_ViolationChart,
#BV_DelinquencyDetailLienAndDemand,
#BV_DelinquencySummary,
#BV_DelinquencyDetailCheckboxSummary,
#BV_PaidInvoices,
#BV_BalanceWithCategoriesByResident,
#BV_ComplianceDetail {
  overflow-x: scroll;
}

.ant-table-row:hover {
  cursor: pointer;
}

.wrap-text-anywhere {
  overflow-wrap: anywhere;
}

.ant-table-tbody>tr:nth-child(2n+1) {
  background-color: rgb(245, 245, 245);
}

h1.ant-typography,
h2.ant-typography,
h3.ant-typography,
h4.ant-typography,
h1,
h2,
h3,
h4 {
  color: #5a7890;
}

.page-title {
  margin-top: 0;
  margin-bottom: 0 !important;
  font-size: 28px;
  line-height: normal;
  font-weight: 700 !important;
}

.sub-heading-text {
  margin-bottom: 0 !important;
}

.description-text {
  margin-top: 8px;
}

.serach-bar {
  border: 1px solid #cdcdcd;
  height: 32px;
  width: 250px;
}

.select-box {
  width: 250px;
  height: 32px;
}

.table-wrapper table {
  min-width: 1000px !important;
}

table thead th,
table tbody td {
  padding-left: 8px !important;
}


// MEDIA QUERRY

@media (maX-width: 1500px) {
  .select-box {
    width: 200px;
  }
}

@media (maX-width: 1200px) {
  .select-box {
    width: 180px;
  }
}

@media (maX-width: 900px) {
  .select-box {
    width: 150px;
  }
}

@media (maX-width: 600px) {
  .w-mo-100{
    width: 100%;
  }
  
  .page-title {
    font-size: 24px !important;
  }

  .serach-bar {
    width: 100%;
  }

  .select-box {
    width: 100%;
  }
}
.text-danger{
  color: red;
}