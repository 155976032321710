.ant-layout-sider-zero-width-trigger.ant-layout-sider-zero-width-trigger-left {
  background: #5a7890;
}

.ant-menu.ant-menu-sub.ant-menu-inline {
  background: #ffffff !important;
}

.boardvue-elements {
  background: #5a7890;

  .ant-menu-title-content {
    color: #ffffff;
  }

  &.ant-menu-item-selected .ant-menu-title-content {
    color: #5a7890;
  }

  .ant-menu-title-content:hover {
    color: #5a7890;
  }
}
.ant-menu-submenu-title{
  padding-left: 10px !important;
}

.ant-menu-item {
  padding-left: 10px !important;
  border-bottom: 1px solid #5a7890;
  &.boardvue-elements {
    border-bottom: 1px solid #ffffff;
  }
  
}

.ant-menu-title-content {
  user-select: none;
  margin: 0 !important;
}

.ant-menu-submenu-title{
  border-bottom: 1px solid #5a7890;
}

.ant-layout-sider-children{
  overflow-y: auto;
}