.add-button {
    position: absolute;
    display: none;
    bottom: 0;
    right: 0;
    text-decoration: underline;
    z-index: 999;
  }

  .ant-picker-cell-in-view:hover .add-button {
    display: block;
  }

  .calendar-events{
    top: 10px;
    position: absolute;
    overflow-x: hidden;
    overflow-y: auto;
    height: 90px;
    padding: 0;
  }