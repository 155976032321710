.allAccessCard{
    transition: 300ms;
    border:none;
    box-shadow: 1px 1px 5px #5a7890;
    &:hover{
        transform: translateY(-1px);
        box-shadow: 1px 1px 15px #5a7890,
        1px 1px 0px #5a7890, 
        1px 1px 0px #5a7890;
    }
}