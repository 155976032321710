.associationDocuments {
    .ant-table-expanded-row > .ant-table-cell {
        padding: 0 !important;
    }

    .ant-table-expanded-row > .ant-table-cell:first-child {
        border: none;
    }

    .ant-table-row .ant-table-row-expand-icon-cell {
        text-align: right;
    }
}